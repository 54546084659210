import { useEffect, useRef, useState } from "react";
import { Button as BsButton, Col, Form, FormGroup, Row } from "reactstrap";
import { Button } from 'src/components';
import { config } from "src/config";
import * as url from 'src/helpers/url_helper';
import {
    useGetCommentsDataByMutation,
} from 'src/rtk/features/disertation-thesis/crudApi';
import { CommentSectionModal } from "../DissertationAndThesis/CommentSectionForThesisDissertation/CommentSectionModal";
import ProtocolViewInThesisDissertation from "../DissertationAndThesis/ProtocolSummaryView/ProtocolViewInThesisDissertation";

const DynamicViewDetails = (props: any) => {
    const { dynamicInputFields, activeStates, setActiveStates, activeStateId, thesisDetails, infoForView, columnName } = props;

    const fileRef = useRef<any>();
    const [fileArray, setFileArray] = useState<any>([]);
    const [messages, setMessages] = useState<any>([]);
    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);
    // console.log(dynamicInputFields[activeStateId]?.column_name);
    const lastIndex: number = Object.keys(dynamicInputFields)?.length!;
    const lastActiveId: string = Object.keys(dynamicInputFields)[lastIndex - 1];

    const [
        commentsDataList,
        {
            isLoading: commentsDataListLoading,
            isSuccess: commentsDataListLoadingSuccess,
            error: commentsDataListLoadingError,
        },
    ] = useGetCommentsDataByMutation();

    const getCommentsData = (thesis_dissertation_id: string, column_id: string) => {
        commentsDataList(`${url.THESIS_DISSERTATION_ENTRY_COMMENT_LIST}/${thesis_dissertation_id}/${column_id}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessages(result?.data?.data);
            }
        });
    };

    useEffect(() => {
        if (thesisDetails?.data?.id && activeStateId) {
            getCommentsData(thesisDetails?.data?.id, activeStateId);
        }
    }, [thesisDetails, activeStateId]);

    useEffect(() => {
        if (infoForView?.thesis_setup_column_name === 'Additional Files') {
            const file_array = JSON.parse(infoForView?.file_array);
            setFileArray(file_array);
        }
    }, [infoForView]);

    let html = `
        <style>
            table td {
                border: 1px solid black;
            }
        </style>
    `;

    return (
        <Form
            className="custom-form"
            onSubmit={(e: any) => {
                e.preventDefault();
                // handleSubmit();
            }}
            onReset={(e: any) => {
                e.preventDefault();
                // handleReset(e);
            }}
        >
            <div className="row">
                <div className="col-md-12 col-sm-12 my-3">
                    <Row className="justify-content-between mb-2 block-heading">
                        <Col sm={6}>
                            <h5>{columnName.toLowerCase() === 'title' ? columnName : infoForView?.thesis_setup_column_name}</h5>
                        </Col>
                        <Col sm={'auto'} className='btn noti-icon floating-comments-btn'>
                            {!['title', 'protocol'].includes(columnName.toLowerCase()) &&
                                <>
                                    <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                                    <BsButton
                                        type='button'
                                        className='btn-sm'
                                        onClick={(e) => {
                                            setShowCommentSectionModal(!showCommentSectionModal);
                                        }}
                                    >
                                        <i className='fas fa-comments font-size-14'></i>
                                    </BsButton>
                                </>
                            }
                        </Col>
                    </Row>
                    {infoForView?.details_text ?
                        <div dangerouslySetInnerHTML={{ __html: infoForView?.details_text ? html + infoForView?.details_text : '' }}></div>
                        :
                        null}
                    {columnName?.toLowerCase() === 'protocol' ?
                        <ProtocolViewInThesisDissertation id={thesisDetails?.data?.title?.thesis_protocol?.id} />
                        :
                        null
                    }
                    {columnName?.toLowerCase() === 'title'
                        ?
                        <p className={'fw-bold'}>
                            {thesisDetails?.data?.title?.title_of_thesis ?? ''}
                        </p>
                        :
                        null}
                </div>
                <div className="col-md-12 col-sm-12 my-3">
                    {infoForView?.details_file ?
                        <a
                            className="btn btn-link"
                            href={config.APP_URL + infoForView?.details_file}
                            rel="noreferrer"
                            target="_blank"
                        >
                            Attachement
                        </a>
                        : null}
                </div>
                <div className="col-md-12 col-sm-12 my-3">
                    {infoForView?.file_array !== null ?
                        <div className="row">
                            {fileArray?.map((item: any, index: number) => (
                                <div key={item?.name} className="col-md-2 col-lg-2 col-sm-4">
                                    <a
                                        className="btn btn-link"
                                        href={config.APP_URL + item?.file}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Attachement {++index}
                                    </a>
                                </div>
                            ))}
                        </div>
                        : null
                    }
                </div>
                {/* <div className="col-md-12 col-sm-12 my-3">
                    <div>
                        <Label><strong>Comments</strong></Label>
                        <InputField
                            type="textarea"
                            placeholder="Enter Your Comments (If any)"
                            name="comments"
                        />
                    </div>
                </div> */}

                <div className="col-md-12 col-sm-12 my-3">
                    <FormGroup className="button-container">
                        <Button
                            type="button"
                            text="< Previous"
                            className="btn btn-light me-2 start-button"
                            onClick={(e) => {
                                const newActiveStates: any = { ...activeStates };
                                const newActiveStatesList: string[] = Object.keys(newActiveStates);
                                Object.keys(newActiveStates)?.forEach((activeId: string, index: number) => {
                                    if (activeId === activeStateId && index > 0) {
                                        let i = index - 1;

                                        while (i > 0 && dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
                                            i = i - 1;
                                        }

                                        console.log(i);

                                        if (i >= 0 && !dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
                                            newActiveStates[activeId] = false;
                                            newActiveStates[newActiveStatesList[i]] = true;
                                        }
                                    }
                                });
                                setActiveStates(newActiveStates);
                                window.scrollTo(0, 0);
                            }}
                        ></Button>
                        <Button
                            type="button"
                            text="Next >"
                            className="btn btn-light me-2 end-button"
                            onClick={(e) => {
                                const newActiveStates: any = { ...activeStates };
                                const newActiveStatesList: string[] = Object.keys(newActiveStates);
                                Object.keys(newActiveStates)?.forEach((activeId: string, index: number) => {
                                    if (activeId === activeStateId && index < lastIndex - 1) {
                                        let i = index + 1;

                                        while (i < lastIndex - 1 && dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
                                            i = i + 1;
                                        }

                                        console.log(i);

                                        if (i <= lastIndex - 1 && !dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
                                            newActiveStates[activeId] = false;
                                            newActiveStates[newActiveStatesList[i]] = true;
                                        }
                                    }
                                });
                                setActiveStates(newActiveStates);
                                window.scrollTo(0, 0);
                            }}
                        ></Button>
                    </FormGroup>
                    {/* <FormGroup className="mb-3 mt-n5 text-center">
                        <Button
                            type="button"
                            text={lastActiveId === activeStateId ? 'Submit' : 'Save'}
                            className="btn btn-success me-2"
                            
                        ></Button>
                    </FormGroup> */}
                </div>

                {showCommentSectionModal ?
                    <CommentSectionModal
                        thesisDissertationFile={thesisDetails}
                        thesisDissertationFormSetupId={activeStateId}
                        thesisDissertationFormSetupColumnName={dynamicInputFields[activeStateId]?.column_name}
                        showModal={showCommentSectionModal}
                        setShowModal={setShowCommentSectionModal}
                        setMessageCount={setMessages}
                    />
                    :
                    null
                }
            </div>
        </Form>
    );
};

export default DynamicViewDetails;