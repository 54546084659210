import React, { useState } from 'react';
import defaultSrc from 'src/assets/images/users/default-avatar.png';
interface ImageComponentProps {
  src: string;
  alt?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
}

const DisplayImage: React.FC<ImageComponentProps> = ({ src, alt = "Default Alt", className, onClick }) => {
  const [imageSrc, setImageSrc] = useState<string>(src);

  const handleImageError = () => {
    // If the image fails to load, set the source to the default image
    setImageSrc(defaultSrc);
  };

  return (
    <img
      loading="lazy"
      src={imageSrc}
      alt={alt}
      onError={handleImageError}
      className={className}
      onClick={onClick}
    />
  );
};

export default DisplayImage;

