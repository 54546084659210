import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfileState {
  data: PersonalProfile | null;
}

const initialState: ProfileState = {
  data: JSON.parse(localStorage.getItem('profileData') || 'null'),
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileData: (state, action: PayloadAction<PersonalProfile>) => {
      state.data = action.payload;
      localStorage.setItem('profileData', JSON.stringify(action.payload));
    },
    clearProfileData: (state) => {
      state.data = null;
      localStorage.removeItem('profileData');
    },
  },
});

export const { setProfileData, clearProfileData } = profileSlice.actions;

export default profileSlice.reducer;
