import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import {
  getSelectFormatedData,
  putUrlString,
  setFromErrors,
} from 'src/helpers';
import * as url from 'src/helpers/url_helper';
import {
  useCreateByPostMutation,
  useGetByDataQuery,
  useGetDataQuery,
  useUpdateByPatchMutation,
} from 'src/rtk/features/common/crudApi';
import * as Yup from 'yup';
const initialValues: any = {
  code: '',
  name: '',
  type: '',
  session: '',
  // subject_id: '',
  main_trainings: '',
  description: '',
  duration: '',
  duration_unit: '',
  paid_course: '',
  fee: '',
  required_ind: true,
  status: true,
};

const typeArray = ['Course', 'Exam', 'Main Training'];

export const AddExamInfo = () => {
  const { id } = useParams<{ id: string }>();

  const [createExamInfo, { isSuccess, isError, error }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;
  const history = useHistory();

  const [
    updateExamInfo,
    { isSuccess: isUpdateSuccess, isError: isUpdateError },
  ] = useUpdateByPatchMutation();

  useEffect(() => {
    if (isUpdateSuccess) {
      resetForm();
      toast.success('Exam Info updated successfully');
      history.push('/setup/exam-info');
    }
    if (isUpdateError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isUpdateSuccess, isUpdateError]);

  let courseData: any = {
    queryUrl: url.RTMD_GET_COURSE_LISTS,
    formData: {
      type: 'main-training',
      subject_id: 'no-need',
    },
  };

  const {
    data: setupCourseListData,
    // isLoading: mainTrainingListLoading,
    // isSuccess: mainTrainingListLoadingSuccess,
    // error: mainTrainingListLoadingError,
  } = useGetByDataQuery(courseData);

  // const mainTrainingList: any = setupCourseListData?.data || [];

  const mainTrainingList: any = setupCourseListData?.data
    ? getSelectFormatedData(setupCourseListData?.data, 'id', 'name')
    : [];

  const mainTrainingSelect = (selectedValues: []) => {
    // console.log(selectedValues);
    values['main_trainings'] = [];
    selectedValues.map((item: any) => {
      values['main_trainings'].push(item['value']);
    });
  };

  // const { data: subjects } = useGetSubjectInfosQuery();

  // const subjectOptions: SelectOption[] =
  //   subjects?.data.map((subject) => ({
  //     value: subject.id!,
  //     label: subject.subject_name,
  //   })) || [];

  const typeOptions: SelectOption[] =
    typeArray.map((item) => ({
      value: item.toLowerCase().replaceAll(' ', '-'),
      label: item,
    })) || [];

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setErrors,
    handleChange,
    values,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      // subject_id: Yup.string().required('Please select a subject'),
      type: Yup.string().required('Exam Type is required'),
      name: Yup.string().required('Name is required'),
      code: Yup.string().required('Code is required'),
      duration: Yup.string().required('Duration is required'),
      duration_unit: Yup.string()
        .required('Please Select Duration Unit')
        .min(1, 'Please Select Duration Unit'),

      paid_course: Yup.string().required('Paid Status is required'),

      session: Yup.string().required('Session is required'),
      main_trainings: Yup.string().when('type', (type, schema) => {
        if (type !== 'main-training') {
          return schema.required('Main Training is Required!');
        }
        return schema;
      }),
      fee: Yup.string().when('paid_course', (paid_course, schema) => {
        if (paid_course && parseInt(paid_course) === 1) {
          return schema.required('Fee/Amount is Required!');
        }
        return schema;
      }),
    }),

    onSubmit: (values) => {
      if (id) {
        const updateUrl =
          url.SETUP_EXAM_TRAINING_UPDATE + putUrlString(id, values);

        updateExamInfo(updateUrl);
      } else {
        // createExamInfo({
        //   ...values,
        // });

        let postData = {
          queryUrl: url.SETUP_EXAM_TRAINING_CREATE,
          formData: values,
        };
        createExamInfo(postData);
      }
    },
  });

  if (id) {
    const { data: exam, isError: isExamFetchError } = useGetDataQuery(
      `/setup/v1/setup-exam-training-info/${id}`,
    );

    useEffect(() => {
      if (exam && !isExamFetchError) {
        const data: any = (exam as any).data;

        let applicable_for = data.training_for
          ? data.training_for.split(',')
          : [];
        setValues({
          code: data.code || '',
          name: data.name || '',
          type: data.type || '',
          session: data.session || '',
          // subject_id: data.subject_id || '',
          description: data.description || '',
          duration: data.duration || '',
          duration_unit: data.duration_unit || '',
          paid_course: parseInt(data.paid_course) || 0,
          fee: data.exam_tranning_fee?.exam_fee || 0,
          required_ind: data.required_ind,
          status: data.status,
        });
      }
    }, [exam, isExamFetchError]);
  }

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Exam Info created successfully');
      history.push('/setup/exam-info/');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Add Exam Information"
          breadcrumbItem={[
            {
              link: '/setup/exam-info/',
              name: 'Exam Information',
            },
          ]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-12 col-sm-12 ">
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-plus me-2" />
                Add Examination Information
              </h5>
              <div className="card-body">
                <Form
                  autoComplete="off"
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row className="g-0">
                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="type">Exam Type {values['type']}</Label>
                      <InputField
                        type="select"
                        placeholder="Exam Type"
                        invalid={{ errors, touched }}
                        {...getFieldProps('type')}
                        options={typeOptions}
                      />
                    </FormGroup>

                    {values['type'] !== 'main-training' && (
                      <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                        <Label for="session">Main Training</Label>
                        <InputField
                          type="select"
                          placeholder="Main Training"
                          options={mainTrainingList}
                          invalid={{ errors, touched }}
                          {...getFieldProps('main_trainings')}
                        />
                      </FormGroup>
                    )}

                    <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                      <Label for="name">Exam Name</Label>
                      <InputField
                        type="text"
                        placeholder="Exam Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('name')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                      <Label for="code">Exam Code</Label>
                      <InputField
                        type="text"
                        placeholder="Exam Code"
                        invalid={{ errors, touched }}
                        {...getFieldProps('code')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                      <Label for="session">Exam Session</Label>
                      <InputField
                        type="select"
                        placeholder="Exam Session"
                        options={[
                          {
                            value: 'January-June',
                            label: 'January-June',
                          },
                          {
                            value: 'July-December',
                            label: 'July-December',
                          },
                        ]}
                        invalid={{ errors, touched }}
                        {...getFieldProps('session')}
                      />
                    </FormGroup>

                    {/* <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                      <Label for="subject_id">Select a subject</Label>
                      <InputField
                        type="select"
                        name="subject_id"
                        placeholder="Select a subject"
                        invalid={{ errors, touched }}
                        options={subjectOptions}
                        value={values.subject_id}
                        onChange={handleChange}
                      />
                    </FormGroup> */}

                    <FormGroup className="mb-3 col-md-3 col-sm-6 px-1">
                      <Label for="duration">Duration</Label>
                      <InputField
                        min={0}
                        step={1}
                        type="number"
                        placeholder="Duration"
                        invalid={{ errors, touched }}
                        {...getFieldProps('duration')}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-md-3 col-sm-6 px-1">
                      <Label for="duration_unit">Unit</Label>
                      <InputField
                        type="select"
                        options={[
                          { value: 'day', label: 'Day(s)' },
                          { value: 'week', label: 'Week(s)' },
                          { value: 'month', label: 'Month(s)' },
                          { value: 'year', label: 'Year(s)' },
                        ]}
                        placeholder="Select duration unit"
                        invalid={{ errors, touched }}
                        {...getFieldProps('duration_unit')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3 col-md-12 col-sm-12 px-1">
                      <Label for="description">Description</Label>
                      <InputField
                        type="textarea"
                        placeholder="Description"
                        invalid={{ errors, touched }}
                        {...getFieldProps('description')}
                      />
                    </FormGroup>

                    <div className="col-sm-6 mb-3">
                      <h6>Paid Status</h6>
                      <FormGroup check inline>
                        <Input
                          type="radio"
                          value={1}
                          name="paid_course"
                          id="paid_course_paid"
                          onChange={handleChange}
                        />
                        <Label htmlFor="paid_course_paid" check>
                          Paid
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          type="radio"
                          value={0}
                          name="paid_course"
                          id="paid_course_free"
                          onChange={handleChange}
                        />
                        <Label htmlFor="paid_course_free" check>
                          Free
                        </Label>
                      </FormGroup>
                    </div>

                    {parseInt(values['paid_course']) === 1 && (
                      <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                        <Label for="fee">Fee/Amount</Label>
                        <InputField
                          type="text"
                          placeholder="Fee"
                          invalid={{ errors, touched }}
                          {...getFieldProps('fee')}
                        />
                      </FormGroup>
                    )}

                    <div className="col-sm-12">
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            {...getFieldProps('required_ind')}
                            checked={values.required_ind}
                          />{' '}
                          Required
                        </Label>
                      </FormGroup>
                    </div>

                    <div className="col-sm-12">
                      <h6>Active Status</h6>
                      <FormGroup check className="mb-3">
                        <Input
                          type="checkbox"
                          role="check"
                          checked={values.status}
                          {...getFieldProps('status')}
                        />
                        <Label check>Active</Label>
                      </FormGroup>
                    </div>

                    <div className="text-left mt-2">
                      <Button className="w-25" type="submit" text="Submit" />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
