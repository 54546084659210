import { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { InputField } from 'src/components';
import {
  useGetFcpsCourseListsForLogbookEntryMutation,
  useLazyGetFormByLogbookTypeAndSubjectQuery
} from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import AddCompetencyGroupDetails from './AddCompetencyGroupDetails';
import AddCompetencyGroupTargets from './AddCompetencyGroupTargets';
import AddLogbookEntryDetailsFields from './AddLogbookEntryDetailsFields';
import loader from '../../../assets/images/loader.gif';

interface QueryParams {
  id?: number;
  course_type?: string;
  subject_id?: string;
  main_training?: string;
}

export const LoadLogbookEntryDetails = (props: any) => {
  const { logbookGroup, logbookEntryFieldList, setSearch } = props;

  const [activeTab, setActiveTab] = useState<string>('field_list');

  const [competencyList, setCompetencyList] = useState<AddCompetencyGroup[]>([]);

  const [queryParams, setQueryParams] = useState<QueryParams>(
    {} as QueryParams,
  );

  const [mainTrainings, setMainTrainings] = useState<FcpsCourseList[]>([]);

  const [mainTraining, setMainTraining] = useState<FcpsCourseList>(
    {} as FcpsCourseList,
  );

  const [coreAdvance, setCoreAdvance] = useState<string>('');

  const [forms, setForms] = useState<LogbookEntrySetupMaster[]>([]);

  const [form, setForm] = useState<LogbookEntrySetupMaster>(
    {} as LogbookEntrySetupMaster,
  );

  const [logbookEntrySetupMasters, setLogbookEntrySetupMasters] = useState<LogbookEntrySetupMaster[][]>([]);
  const [logbookEntrySetupMaster, setLogbookEntrySetupMaster] = useState<LogbookEntrySetupMaster[]>([]);

  // const [caseTypes, setCaseTypes] = useState<LogbookEntrySetupMaster[]>([]);

  // const [caseType, setCaseType] = useState<LogbookEntrySetupMaster>(
  //   {} as LogbookEntrySetupMaster,
  // );

  // const [skillTypes, setSkillTypes] = useState<LogbookEntrySetupMaster[]>([]);

  // const [skillType, setSkillType] = useState<LogbookEntrySetupMaster>(
  //   {} as LogbookEntrySetupMaster,
  // );

  const [logbookEntrySetupMasterId, setLogbookEntrySetupMasterId] =
    useState<string>('');

  const logbookEntryGroupTitleWords: string[] = logbookGroup?.title?.split(' -> ');

  const { data: getSubjectInfolist } = useGetSubjectInfosQuery();
  const subjects = getSubjectInfolist?.data;

  const [
    createFcpsCourse,
    {
      data: fcpsCourseListData,
      isSuccess: isFcpsCourseListSuccess,
      isError: isFcpsCourseListError,
      isLoading: isFcpsCourseListLoading,
      error: fcpsCourseListError,
    },
  ] = useGetFcpsCourseListsForLogbookEntryMutation();

  const [
    getForms,
    {
      data: formSetups,
      isSuccess: isFormSetupsSuccess,
      error: formSetupsError,
      isError: isFormSetupsError,
      isLoading: isFormSetupsLoading,
      isFetching: isFormSetupsFetching,
    },
  ] = useLazyGetFormByLogbookTypeAndSubjectQuery();

  const subjectOptions: SelectOption[] =
    subjects?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  const subject = subjects?.find(
    (subject) => subject?.id === logbookGroup?.subject_id,
  );

  useEffect(() => {
    createFcpsCourse(subject?.id!);
  }, [subject]);

  // const handleCompetencyGroupSubmission = (e: any) => {

  //   const competencyParticularsArray: string[] = competencyList?.map((c: AddCompetencyGroup) => {
  //     const { id, ...newObj } = { ...c };
  //     return newObj?.particulars!;
  //   });

  //   createLogbookCompetencyGroup({ particulars: competencyParticularsArray, particulary_type: 6, logbook_entry_setup_master_id: logbookGroup?.skill_type_id ? logbookGroup?.skill_type_id : logbookGroup?.case_type_id });

  // };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (isFcpsCourseListSuccess && fcpsCourseListData?.success) {
      const mainTrainings: FcpsCourseList[] = fcpsCourseListData.data;
      setMainTrainings(mainTrainings);
    } else {
      setMainTrainings([]);
    }
  }, [isFcpsCourseListSuccess, fcpsCourseListData?.success]);

  useEffect(() => {
    const mainTraining = mainTrainings?.find(
      (mainTraining) => mainTraining.id === logbookGroup?.main_training_id,
    );

    setMainTraining(mainTraining!);
  }, [mainTrainings]);

  useEffect(() => {
    const newQueryParams: QueryParams = { ...queryParams };
    newQueryParams.subject_id = logbookGroup?.subject_id;
    newQueryParams.main_training = logbookGroup?.main_training_id;
    newQueryParams.course_type = logbookGroup?.course_type!;
    setQueryParams(newQueryParams);
    setCoreAdvance(logbookGroup?.course_type!);
  }, [mainTraining]);

  useEffect(() => {
    if (
      queryParams.subject_id &&
      queryParams.main_training &&
      queryParams.course_type
    ) {
      queryParams.id = 1;
      getForms(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (formSetups && isFormSetupsSuccess) {
      setForms(formSetups?.data);
    } else {
      console.log(formSetupsError);
    }
  }, [formSetups, isFormSetupsSuccess]);

  useEffect(() => {
    let form = forms?.find(
      (form) =>
        form.id?.toLowerCase().trim() ===
        logbookGroup?.from?.toLowerCase().trim(),
    );
    if (!form) {
      form = forms?.find(
        (form) =>
          form.particulars?.toLowerCase().trim() ===
          logbookGroup?.from?.toLowerCase().trim(),
      );
    }

    const newLogbookEntrySetupMasters: LogbookEntrySetupMaster[][] = [];
    const newLogbookEntrySetupMaster: LogbookEntrySetupMaster[] = [];

    let childrens: LogbookEntrySetupMaster[] = form?.entry_setup_master!;
    let child: LogbookEntrySetupMaster = childrens?.find((c: LogbookEntrySetupMaster) => c.particulars === logbookEntryGroupTitleWords[4])!;
    newLogbookEntrySetupMasters[0] = childrens;
    newLogbookEntrySetupMaster[0] = child;

    let i = 5;
    while (child?.entry_setup_master?.length! > 0) {
      childrens = child?.entry_setup_master!;
      child = childrens?.find((c: LogbookEntrySetupMaster) => c.particulars === logbookEntryGroupTitleWords[i])!;
      newLogbookEntrySetupMasters.push(childrens);
      newLogbookEntrySetupMaster.push(child);
      i = i + 1;
    }

    setForm(form!);
    setLogbookEntrySetupMasters(newLogbookEntrySetupMasters);
    setLogbookEntrySetupMaster(newLogbookEntrySetupMaster);
    setLogbookEntrySetupMasterId(newLogbookEntrySetupMaster[newLogbookEntrySetupMaster.length - 1]?.id!);
  }, [forms]);

  useEffect(() => {
    if (logbookEntryFieldList?.data?.competency_group?.length!) {
      const competencyListOps: any = logbookEntryFieldList?.data?.competency_group?.map((c: LogbookEntrySetupMaster) => {
        return {
          id: c.id!,
          particulars: c.particulars,
          particulary_type: c.particulary_type,
          logbook_entry_setup_master_id: c.logbook_entry_setup_master_id,
          show_minus_btn: false,
        };
      });

      setCompetencyList(competencyListOps);
    } else {
      setCompetencyList([]);
    }
  }, [logbookEntryFieldList]);

  // useEffect(() => {
  //   setCaseTypes(form?.entry_setup_master! ?? []);
  // }, [form]);

  // useEffect(() => {
  //   const caseType: any = caseTypes?.find(
  //     (c: any) => c.id === logbookGroup?.case_type_id,
  //   );

  //   const caseTypeChildData: any = caseTypes?.find(
  //     (c: any) => c.id === logbookGroup?.case_type_id,
  //   )?.entry_setup_master;

  //   setCaseType(caseType);

  //   if (logbookGroup?.case_type_id === '') {
  //     setLogbookEntrySetupMasterId('');
  //   } else {
  //     if (caseTypeChildData?.length === 0) {
  //       setLogbookEntrySetupMasterId(logbookGroup?.case_type_id || '');
  //     }
  //   }
  // }, [caseTypes]);

  // useEffect(() => {
  //   setSkillTypes(caseType?.entry_setup_master! ?? []);
  // }, [caseType]);

  // useEffect(() => {
  //   if (skillTypes?.length > 0) {
  //     const skillType: any = skillTypes?.find(
  //       (s: any) => s.id === logbookGroup?.skill_type_id,
  //     );
  //     setSkillType(skillType);
  //     if (logbookGroup?.skill_type_id === '') {
  //       setLogbookEntrySetupMasterId('');
  //     } else {
  //       setLogbookEntrySetupMasterId(skillType.id || '');
  //     }
  //   }
  // }, [skillTypes]);

  const mainTrainingOptions: SelectOption[] =
    mainTrainings?.map((mainTraining) => ({
      value: mainTraining.id!,
      label: mainTraining.name!,
    })) || [];

  const formOptions: SelectOption[] =
    forms?.map((e: LogbookEntrySetupMaster) => {
      return {
        label: e.particulars!,
        value: e.id!,
      };
    }) ?? [];

  // const caseTypeOptions: SelectOption[] =
  //   caseTypes?.map((e: LogbookEntrySetupMaster) => {
  //     return {
  //       label: e.particulars!,
  //       value: e.id!,
  //     };
  //   }) ?? [];

  // const skillTypeOptions: SelectOption[] =
  //   skillTypes?.map((e: LogbookEntrySetupMaster) => {
  //     return {
  //       label: e.particulars!,
  //       value: e.id!,
  //     };
  //   }) ?? [];

  return (
    <>
      {isFormSetupsFetching || isFcpsCourseListLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <hr />
      <Form
        className="custom-form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label">Subject</Label>
              <InputField
                type="select"
                name="subject"
                value={subject?.id}
                placeholder=""
                options={subjectOptions}
                disabled={true}
              />
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label">Main Training</Label>
              <InputField
                type="select"
                name="mainTraining"
                value={mainTraining?.id}
                placeholder=""
                options={mainTrainingOptions}
                disabled={true}
              />
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label">Core/Specialty</Label>
              <InputField
                type="select"
                name="coreAdvance"
                placeholder=""
                value={coreAdvance}
                options={[
                  { label: 'Core', value: 'core' },
                  { label: 'Specialty', value: 'adv' },
                ]}
                disabled={true}
              />
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label">Form</Label>
              <InputField
                type="select"
                name="form"
                placeholder=""
                value={form?.id}
                options={formOptions}
                disabled={true}
              />
            </FormGroup>
          </div>

          {/* <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label">Case Type</Label>
              <InputField
                type="select"
                name="caseType"
                value={caseType?.id}
                placeholder=""
                options={caseTypeOptions}
                disabled={true}
              />
            </FormGroup>
          </div>

          {skillTypes?.length > 0 ? (
            <div className="col-lg-4 col-md-6 col-sm-12">
              <FormGroup className="mb-3">
                <Label className="form-label">Skill Type</Label>
                <InputField
                  type="select"
                  name="skillType"
                  value={skillType?.id}
                  placeholder=""
                  options={skillTypeOptions}
                  disabled={true}
                />
              </FormGroup>
            </div>
          ) : null} */}

          {logbookEntrySetupMasters?.map((entrySetupMaster: LogbookEntrySetupMaster[], index: number) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label className="form-label">{index === 0 ? 'Case Type' : `Skill Type ${index}`}</Label>
                  <InputField
                    type="select"
                    name={`skillType${index + 1}`}
                    value={logbookEntrySetupMaster[index]?.id}
                    placeholder=""
                    options={
                      entrySetupMaster?.map((e: LogbookEntrySetupMaster) => {
                        return {
                          label: e.particulars!,
                          value: e.id!,
                        };
                      }) ?? []
                    }
                    disabled={true}
                  />
                </FormGroup>
              </div>
            );
          })}
        </div>
      </Form>

      {logbookEntrySetupMasterId !== '' && logbookGroup ? (
        <>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'field_list' ? 'active' : ''}
                onClick={() => toggleTab('field_list')}
              >
                Logbook Entry Field
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'competency_list' ? 'active' : ''}
                onClick={() => toggleTab('competency_list')}
              >
                Competency Group
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'target_list' ? 'active' : ''}
                onClick={() => toggleTab('target_list')}
              >
                Target Setup
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="field_list">
              <AddLogbookEntryDetailsFields
                key={logbookEntrySetupMasterId}
                logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                logbookGroup={logbookGroup}
                logbookEntryFieldList={logbookEntryFieldList}
                setSearch={setSearch}
                submitStatus={true}
                readOnly={false}
              />
            </TabPane>
            <TabPane tabId="competency_list">
              <Row className='mt-2'>
                <Col>
                  {competencyList &&
                    <AddCompetencyGroupDetails
                      key={logbookEntrySetupMasterId}
                      logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                      logbookGroup={logbookGroup}
                      logbookEntryFieldList={logbookEntryFieldList}
                      setSearch={setSearch}
                      submitStatus={true}
                      readOnly={false}
                    />
                  }
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="target_list">
              <Row className='mt-2'>
                <Col>
                  {competencyList &&
                    <AddCompetencyGroupTargets
                      key={logbookEntrySetupMasterId}
                      logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                      logbookGroup={logbookGroup}
                      logbookEntryFieldList={logbookEntryFieldList}
                      setSearch={setSearch}
                      submitStatus={true}
                      readOnly={false}
                    />
                  }
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </>
      ) : (
        <>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'field_list' ? 'active' : ''}
                onClick={() => toggleTab('field_list')}
              >
                Logbook Entry Field
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'competency_list' ? 'active' : ''}
                onClick={() => toggleTab('competency_list')}
              >
                Competency Group
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'target_list' ? 'active' : ''}
                onClick={() => toggleTab('target_list')}
              >
                Target Setup
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="field_list">
              <AddLogbookEntryDetailsFields
                key={logbookEntrySetupMasterId}
                logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                logbookGroup={logbookGroup}
                logbookEntryFieldList={logbookEntryFieldList}
                setSearch={setSearch}
                submitStatus={true}
                readOnly={true}
              />
            </TabPane>
            <TabPane tabId="competency_list">
              <Row className='mt-2'>
                <Col>
                  {competencyList &&
                    <AddCompetencyGroupDetails
                      key={logbookEntrySetupMasterId}
                      logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                      logbookGroup={logbookGroup}
                      logbookEntryFieldList={logbookEntryFieldList}
                      setSearch={setSearch}
                      submitStatus={true}
                      readOnly={true}
                    />
                  }
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="target_list">
              <Row className='mt-2'>
                <Col>
                  {competencyList &&
                    <AddCompetencyGroupTargets
                      key={logbookEntrySetupMasterId}
                      logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                      logbookGroup={logbookGroup}
                      logbookEntryFieldList={logbookEntryFieldList}
                      setSearch={setSearch}
                      submitStatus={true}
                      readOnly={true}
                    />
                  }
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </>
      )}
    </>
  );
};
