import { useEffect, useState } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import { useGetDynamicFormPropertyByParticularQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import loader from '../../../../assets/images/loader.gif';
import DynamicForm from './DynamicLogbookEntry';

export const TraineeLogbookEntryGeneralType = (props: any) => {
    const { form, formName, setFormId, setForm } = props;
    const [skillTypeId, setSkillTypeId] = useState<string>('');
    const [skillTypeName, setSkillTypeName] = useState<string>('');
    const [selectedCaseType, setSelectedCaseType] = useState<any>('');
    const [logbookEntrySetupMasters, setLogbookEntrySetupMasters] = useState<LogbookEntrySetupMaster[][]>([]);
    const [logbookEntrySetupMaster, setLogbookEntrySetupMaster] = useState<LogbookEntrySetupMaster[]>([]);
    const [competencyGroup, setCompetencyGroup] = useState<LogbookEntrySetupMaster[]>([]);

    const {
        data: logbookEntrySetupDetailsBySkillType,
        isError: isFormFieldsSkillError,
        isLoading: isFormFieldsSkillLoading,
    } = useGetDynamicFormPropertyByParticularQuery(skillTypeId);
    const formFieldsBySkillType = logbookEntrySetupDetailsBySkillType?.data || [];

    useEffect(() => {
        const newLogbookEntrySetupMasters: LogbookEntrySetupMaster[][] = [];
        const newLogbookEntrySetupMaster: LogbookEntrySetupMaster[] = [];
        if (form) {
            newLogbookEntrySetupMasters[0] = form?.entry_setup_master!;
            newLogbookEntrySetupMaster[0] = form!;
            setLogbookEntrySetupMasters(newLogbookEntrySetupMasters);
            setLogbookEntrySetupMaster(newLogbookEntrySetupMaster);
        } else {
            setLogbookEntrySetupMasters([]);
            setLogbookEntrySetupMaster([]);
        }
    }, []);

    const handleEntrySetupMasterChilds = (e: any, index: number, entrySetupMaster?: LogbookEntrySetupMaster[]) => {
        let value = '';

        if (index === 0) {
            value = e?.value;

            const caseType: LogbookEntrySetupMaster = entrySetupMaster?.find((item: LogbookEntrySetupMaster, index: number) => item.id === value)!;
            setSelectedCaseType({ label: caseType?.particulars ?? '', value: caseType?.id ?? '' });
        } else {
            value = e?.target?.value;
        }

        const newLogbookEntrySetupMasters: LogbookEntrySetupMaster[][] = [...logbookEntrySetupMasters];
        const newLogbookEntrySetupMaster: LogbookEntrySetupMaster[] = [...logbookEntrySetupMaster];

        if (value) {
            const skillType: LogbookEntrySetupMaster = logbookEntrySetupMasters[index]?.find((s: any) => s.id === value)!;

            if (skillType?.entry_setup_master?.length! > 0 && !newLogbookEntrySetupMasters[index + 1]) {
                newLogbookEntrySetupMasters.push(skillType?.entry_setup_master!);
                newLogbookEntrySetupMaster[index] = skillType;
                newLogbookEntrySetupMaster.forEach((entrySetupMaster: LogbookEntrySetupMaster, i: number) => {
                    if (i > index) {
                        newLogbookEntrySetupMaster[i] = { id: '', setup_logbook_master_id: '', status: false };
                    }
                });
                setSkillTypeId('');
                setSkillTypeName('');
            } else if (skillType?.entry_setup_master?.length! > 0 && newLogbookEntrySetupMasters[index + 1]) {
                newLogbookEntrySetupMasters[index + 1] = skillType?.entry_setup_master!;
                newLogbookEntrySetupMaster[index] = skillType;
                newLogbookEntrySetupMaster.forEach((entrySetupMaster: LogbookEntrySetupMaster, i: number) => {
                    if (i > index) {
                        newLogbookEntrySetupMaster[i] = { id: '', setup_logbook_master_id: '', status: false };
                    }
                });
                setSkillTypeId('');
                setSkillTypeName('');
            } else if (skillType?.entry_setup_master?.length! === 0) {
                newLogbookEntrySetupMaster[index] = skillType;
                if (newLogbookEntrySetupMaster.length > index) {
                    newLogbookEntrySetupMaster.splice(index + 1, newLogbookEntrySetupMaster.length - index - 1);
                }
                if (newLogbookEntrySetupMasters.length > index) {
                    newLogbookEntrySetupMasters.splice(index + 1, newLogbookEntrySetupMasters.length - index - 1);
                }
                setSkillTypeId(skillType?.id!);
                setSkillTypeName(skillType?.particulars!);
                setCompetencyGroup(skillType?.competency_group!);
            }
        } else {
            newLogbookEntrySetupMasters.splice(index + 1, newLogbookEntrySetupMasters.length - index - 1);
            newLogbookEntrySetupMaster[index] = { id: '', setup_logbook_master_id: '', status: false };
            setSkillTypeId('');
            setSkillTypeName('');
        }      

        setLogbookEntrySetupMasters(newLogbookEntrySetupMasters);
        setLogbookEntrySetupMaster(newLogbookEntrySetupMaster);
    };

    const handleFormCaseAndSkillReset = () => {
        setFormId('');
        setLogbookEntrySetupMaster([]);
        setLogbookEntrySetupMasters([]);
    };

    return (
        <>
            {isFormFieldsSkillLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            {logbookEntrySetupMasters?.map((entrySetupMaster: LogbookEntrySetupMaster[], index: number) => {
                return (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                        {
                            index === 0 ?
                                <FormGroup className="mb-3">
                                    <Label className="form-label">{'Case Type'}</Label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        name={`skillType${index + 1}`}
                                        value={selectedCaseType}
                                        placeholder=""
                                        options={
                                            entrySetupMaster?.map((e: LogbookEntrySetupMaster) => {
                                                return {
                                                    label: e.particulars!,
                                                    value: e.id!,
                                                };
                                            }) ?? []
                                        }
                                        onChange={(e: any) => {
                                            handleEntrySetupMasterChilds(e, index, entrySetupMaster);
                                        }}
                                    />
                                </FormGroup>
                                :
                                <FormGroup className="mb-3">
                                    <Label className="form-label">{`Skill Type ${index}`}</Label>
                                    <InputField
                                        type="select"
                                        name={`skillType${index + 1}`}
                                        value={logbookEntrySetupMaster[index]?.id}
                                        placeholder=""
                                        options={
                                            entrySetupMaster?.map((e: LogbookEntrySetupMaster) => {
                                                return {
                                                    label: e.particulars!,
                                                    value: e.id!,
                                                };
                                            }) ?? []
                                        }
                                        onChange={(e) => {
                                            handleEntrySetupMasterChilds(e, index);
                                        }}
                                    />
                                </FormGroup>
                        }
                    </div>
                );
            })}
            {logbookEntrySetupDetailsBySkillType &&
                !isFormFieldsSkillError ? (
                <DynamicForm
                    dynamicFormProps={formFieldsBySkillType}
                    formName={formName}
                    caseTypeName={logbookEntrySetupMaster[0]?.particulars!}
                    skillTypeName={skillTypeName}
                    entrySetupMasterId={skillTypeId}
                    competencyGroup={competencyGroup}
                    handleFormAndCaseReset={handleFormCaseAndSkillReset}
                    setForm={setForm}
                />
            ) : null}
        </>
    );
};
