import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateLogbookEntrySetupCompetencyGroupMutation } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import * as Yup from 'yup';
import loader from '../../../assets/images/loader.gif';

const initialValues: AddCompetencyGroup = {
    id: '',
    logbook_entry_setup_master_id: '',
    particulars: '',
    particulary_type: 6,
    competence_parent: '',
    sl_no: 0,
    alphabetic_order: false,
    has_competence_levels: 0,
    competence_level_type: '',
};

const EditCompetencyGroupDetails = (props: any) => {
    const { logbookEntryFieldList, competencyGroupDetailsForEdit, setShowAddForm } = props;

    const [selectedCompetenceParent, setSelectedCompetenceParent] = useState<any>('');
    const [alphabeticOrderStatus, setAlphabeticOrderStatus] = useState<boolean>(false);
    const [hasCompetenceLevels, setHasCompetenceLevels] = useState<boolean>(false);
    const [selectedCompetenceLevelType, setSelectedCompetenceLevelType] = useState<any>('');

    const competenceLevelTypeOptions: SelectOption[] =
        useGetLookupDataQuery('competence_level')
            .data?.data[0]?.childs.map((item) => ({
                value: item.name,
                label: item.name,
            }))
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            }) || [];

    const [
        createLogbookCompetencyGroup,
        {
            data: competencyGroupData,
            isSuccess: isCompetencyGroupDataSuccess,
            isError: isCompetencyGroupDataError,
            isLoading: isCompetencyGroupDataLoading,
            error: competencyGroupDataError
        }
    ] = useCreateLogbookEntrySetupCompetencyGroupMutation();

    const competenceParentOptions: SelectOption[] = logbookEntryFieldList?.data?.competency_group_with_raw?.map((competence: any, index: number) => {
        return { label: competence.particulars, value: competence.id };
    });

    const err: CustomError = competencyGroupDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({
            particulars: Yup.string().trim().required('Competence title is required'),
        }),

        onSubmit: (values) => {

            if (hasCompetenceLevels) {
                values.has_competence_levels = 1;
            } else {
                values.has_competence_levels = 0;
            }

            createLogbookCompetencyGroup({
                ...values,
            });

            console.log(values);
        },
    });

    const {
        handleSubmit,
        handleChange,
        getFieldProps,
        setFieldValue,
        setValues,
        values,
        touched,
        errors,
        resetForm,
        setErrors,
    } = formik;

    useEffect(() => {
        const data: any = competencyGroupDetailsForEdit;

        setValues({
            id: data?.id,
            logbook_entry_setup_master_id: data?.logbook_entry_setup_master_id,
            particulars: data?.particulars,
            particulary_type: data?.particulary_type,
            competence_parent: data?.parent?.id,
            sl_no: data?.sl_no,
            alphabetic_order: data?.alphabetic_order,
            has_competence_levels: data?.has_competence_levels,
            competence_level_type: data?.competence_level_type,
        });

        setAlphabeticOrderStatus(data?.alphabetic_order ?? false);
        setHasCompetenceLevels(data?.has_competence_levels ?? false);
        setSelectedCompetenceLevelType({ label: data?.competence_level_type, value: data?.competence_level_type });

        const selectedCompetenceParentParticulars: string = competenceParentOptions?.find((c: SelectOption, index: any) => c.value === data?.parent?.id)?.label ?? '';
        setSelectedCompetenceParent({ label: selectedCompetenceParentParticulars, value: data?.parent?.id });
    }, []);

    useEffect(() => {
        if (isCompetencyGroupDataSuccess) {
            handleFormReset();
            toast.success('Competence group added successfully');
            setShowAddForm(false);
        }
        if (isCompetencyGroupDataError) {
            toast.error(err?.data?.message || err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isCompetencyGroupDataSuccess, isCompetencyGroupDataError]);

    const handleAlphabeticOrderStatus = (e: any) => {
        setAlphabeticOrderStatus(e.target.checked);
    };

    const handleHasCompetenceLevels = (e: any) => {
        setHasCompetenceLevels(e.target.checked);

        if (!e.target.checked) {
            setFieldValue('competence_level_type', '');
            setSelectedCompetenceLevelType({ label: '', value: '' });
        }
    };

    const handleFormReset = () => {
        resetForm();
        setSelectedCompetenceParent({ label: '', value: '' });
        setSelectedCompetenceLevelType({ label: '', value: '' });
        setAlphabeticOrderStatus(false);
        setHasCompetenceLevels(false);
    };

    useEffect(() => {
        if (selectedCompetenceParent?.value) {
            setFieldValue('competence_parent', selectedCompetenceParent?.value);
        } else {
            setFieldValue('competence_parent', '');
        }
    }, [selectedCompetenceParent]);

    useEffect(() => {
        if (selectedCompetenceLevelType?.value) {
            setFieldValue('competence_level_type', selectedCompetenceLevelType?.value);
        } else {
            setFieldValue('competence_level_type', '');
        }
    }, [selectedCompetenceLevelType]);

    return (
        <>
            {isCompetencyGroupDataLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Form
                className="custom-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    handleFormReset();
                }}
            >
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <FormGroup className="mb-3 mt-3">
                            <Label htmlFor="competence_title">Competence Title</Label>
                            <InputField
                                type="text"
                                placeholder="Competence Title"
                                invalid={{ errors, touched }}
                                {...getFieldProps('particulars')}
                            />
                        </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <FormGroup className="mb-3 mt-3">
                            <Label htmlFor="competence_parent">Competence Parent (Optional)</Label>
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                value={selectedCompetenceParent}
                                onChange={setSelectedCompetenceParent}
                                options={competenceParentOptions}
                                placeholder='Select...'
                                name="competence_parent"
                                id="competence_parent"
                            />
                        </FormGroup>
                    </div>

                    <div className="col-lg-2 col-md-4 col-sm-12">
                        {!alphabeticOrderStatus ?
                            <FormGroup className="mb-3 mt-3">
                                <Label for="sl_no">Serial No</Label>
                                <InputField
                                    type="number"
                                    placeholder=""
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('sl_no')}
                                />
                            </FormGroup>
                            :
                            null}
                    </div>

                    <div className="col-lg-2 col-md-4 col-sm-12">
                        <FormGroup check className="mb-3 mt-5">
                            <Label check>
                                <Input
                                    name="alphabetic_order"
                                    id="alphabetic_order"
                                    type="checkbox"
                                    onChange={(e) => {
                                        handleAlphabeticOrderStatus(e);
                                    }}
                                    checked={alphabeticOrderStatus}
                                />{' '}
                                Alphabetic Order
                            </Label>
                        </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <FormGroup check className="mb-3 mt-5">
                            <Label check>
                                <Input
                                    name="has_competence_levels"
                                    id="has_competence_levels"
                                    type="checkbox"
                                    onChange={(e) => {
                                        handleHasCompetenceLevels(e);
                                    }}
                                    checked={hasCompetenceLevels}
                                />{' '}
                                Has Competence Levels?
                            </Label>
                        </FormGroup>
                    </div>

                    {hasCompetenceLevels ?
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <FormGroup className="mb-3">
                                <Label htmlFor="competence_level_type">Competence Level Type</Label>
                                <Select
                                    isClearable={true}
                                    isSearchable={true}
                                    value={selectedCompetenceLevelType}
                                    onChange={setSelectedCompetenceLevelType}
                                    options={competenceLevelTypeOptions}
                                    placeholder='Select...'
                                    name="competence_level_type"
                                    id="competence_level_type"
                                />
                            </FormGroup>
                        </div>
                        :
                        null}

                    <div className="col-sm-12">
                        <div className="row justify-content-center">
                            <div className="col-sm-2">
                                <Button
                                    type="submit"
                                    color="secondary"
                                    text="Submit"
                                    className="me-2 mb-2"
                                ></Button>
                                <Button
                                    type="reset"
                                    color="warning"
                                    text="Reset"
                                    className="me-2 mb-2"
                                ></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default EditCompetencyGroupDetails;
