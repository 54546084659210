import Icon from '@ailibs/feather-react-ts';
import Pusher from 'pusher-js';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { getTime } from 'src/helpers';
import { useAppSelector } from 'src/rtk/app/hook';
import { RootState } from 'src/rtk/app/store';
// import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import { useGetNotificationStatusQuery } from 'src/rtk/features/user/userApi';

const NotificationDropdown = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const authUser = useAppSelector((state) => state.auth.user);
  // const { data: personalProfile, isError: isDeptFetchError, isLoading: isProfileLoading } = useGetPersonalProfileQuery();
  const { data: notification, isError: isNotificationError, isLoading: isNotificationLoading, refetch } = useGetNotificationStatusQuery();
  const profileInfo = useAppSelector((state) => state.profile.data);

  useEffect(() => {
    pusherEvent()
  }, []);


  const pusherEvent = () => {
    var pusher = new Pusher(`${process.env.REACT_APP_PUSHER_API_KEY}`, {
      cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
    });

    var channel = pusher.subscribe("bcps");
    channel.bind("bcps-push-notification", (data: any) => {
      console.log(data);
      refetch();
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 600000);

    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      {profileInfo?.type === 'Trainee' && profileInfo?.details?.current_session_and_supervisor_info?.supervisor?.name ?
        <div className='m-2'>
          <div>
            <h5>{profileInfo?.details?.subject?.subject_name}</h5>
          </div>

          <div>
            <h6>Current Supervisor : {profileInfo?.details?.current_session_and_supervisor_info?.supervisor?.name}</h6>
          </div>
        </div> :
        <div className='mt-4'>
          <h5>{profileInfo?.details?.subject?.subject_name}</h5>
        </div>
      }
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <Icon name="bell" className="icon-lg" />
          <span className="badge bg-danger rounded-pill">{notification?.data?.un_seen}{notification?.data?.un_seen > 10 ? '+' : ''}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> Notifications </h6>
              </Col>
              <div className="col-auto">
                <Link to="/notification" className="small">
                  {' '}
                  View All
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ maxHeight: '350px' }}>
            {notification?.data?.notifications?.map((item: any) => (
              <div className="text-reset notification-item row" key={item.id}>
                <div className="d-flex col">
                  <div className="me-3">
                    <i className='mdi mdi-circle' style={{ color: '#6a5acd' }} />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{item?.title}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {item?.body}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" /> {getTime(item?.created_at)} ago
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
            }
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notification"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i> View all
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
