import ReactApexChart from "react-apexcharts";

const barchart = () => {
  const series = [
    {
      name: 'Logbook Target',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },

    {
      name: 'Achievement',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];
  const options = {
    chart: {
      height: '100%',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },

    title: {
      text: 'Procedural Skill'
    },
    fill: {
      opacity: 1
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#8FB9FF", "#79ECA7"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Medical psychiatry",
        ["Acute Medicine", "and Critical illness"],
        ["Poisoning", "and Envenomation"],
        ["Infectious", "and Tropical diseases"],
        "Rheumatological Diseases",
        ["Nephrology including", "GU system"],
        "Nervous System",
        ["Endocrine diseases", "including Diabetes", "mellitus"],
        ["Blood disorders", "& Transfusion Medicine"],
        ["Gastrointestinal", "& Nepato biliary", "system and Pancreas"],
      ],
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height="500" className="apex-charts text-dark" />
  );
};

export default barchart;
