import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, CardHeader, CardBody, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { useGetNoticeTemplateListsQuery } from 'src/rtk/features/setup/noticeTemplateList/setupNoticeTemplateListApi';
import NoticeTemplateTableRow from './NoticeTemplateTableRow';

const NoticeTemplateLists = () => {
  const { data, error, isLoading, isError } = useGetNoticeTemplateListsQuery();

  const err: CustomError = error as CustomError;
  const noticeTemplateLists = data?.data || [];   

  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && noticeTemplateLists?.length > 0) {
    content = noticeTemplateLists.map((noticeTemplateList: any, index: any) => (
      <NoticeTemplateTableRow
        noticeTemplateList={noticeTemplateList}
        index={index}
        key={noticeTemplateList.id}
      />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '/setup/notice-template-list/add', name: 'Add Notice Template' }]}
          title={'Notice Template List'}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link className="btn btn-sm btn-primary" to="/setup/notice-template-list/add">
              Add Notice Template
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Tamplate Name</th>
                    <th>Notice Type</th>
                    <th>Department</th>
                    <th>Notice Subject</th>
                    {/* <th>Notice Header</th> */}
                    <th>Notice Body</th>
                    {/* <th>Notice Footer</th> */}
                    <th>Notice Template Status</th>                                                                                                                         
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default NoticeTemplateLists;
