import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { putUrlString } from 'src/helpers';
import { useUpdateLogbookEntrySetupDetailMutation } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import * as Yup from 'yup';
import loader from '../../../assets/images/loader.gif';
import EditLogbookEntryDetailsOptions from './EditLogbookEntryDetailsOptions';

const initialValues: LogbookEntryDetails = {
  id: '',
  logbook_entry_setup_master_id: '',
  logbook_entry_setup_detail_id: '',
  column_name: '',
  details_text: '',
  details_text_desc: '',
  column_data_type: '',
  column_value_type: '',
  searching_item: '',
  required_ind: 1,
  is_show: 1,
  sl_no: 0,
  search_ind: 1,
  default_ind: 1,
  status: true,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

export const EditLogbookEntryDetails = (props: any) => {
  // const { id } = useParams<{ id: string }>();
  const { logbookEntryDetails, setShowAddForm } = props;
  // const {
  //   data: logbookEntryDetails,
  //   isError: isLogbookEntryDetailsFetchError,
  // } = useGetLogbookEntrySetupDetailQuery(logbookEntryField?.id!);
  const [updateLogbookEntryDetails, { isSuccess, isError, isLoading, error }] =
    useUpdateLogbookEntrySetupDetailMutation();
  const [requiredStatus, setRequiredStatus] = useState<boolean>(true);
  const [logbookEntrySetupMasterId, setLogbookEntrySetupMasterId] =
    useState<string>('');
  const [columnValueStatus, setColumnValueStatus] = useState<boolean>(true);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [inputList, setInputList] = useState<any>([]);
  const [logbookEntryFieldOptions, setLogbookEntryFieldOptions] = useState<any>(
    [{ label: '', value: '' }],
  );

  // const { data: logbookEntrySetupMastersData } = useGetLogbookEntrySetupMastersQuery();
  // const logbookEntrySetupMasters = logbookEntrySetupMastersData?.data || [];

  // const { data: logbookEntrySetupDetailsData } = useGetLogbookEntrySetupDetailsQuery();
  // const logbookEntrySetupDetails = logbookEntrySetupDetailsData?.data?.filter((l: LogbookEntryDetails) => l.logbook_entry_setup_master_id === logbookEntrySetupMasterId) || [];

  const err: CustomError = error as CustomError;

  // const history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      column_name: Yup.string().trim().required('Field name is required'),
      // logbook_entry_setup_master_id: Yup.string().required('Please select the logbook entry setup master'),
    }),

    onSubmit: (values) => {
      if (requiredStatus) {
        values.required_ind = 1;
      } else {
        values.required_ind = 0;
      }

      const { options, ...restValues } = values;

      const url = putUrlString(logbookEntryDetails?.id, restValues);
      updateLogbookEntryDetails({ url: url, options: options ?? [] });
    },
  });

  const {
    handleSubmit,
    handleChange,
    getFieldProps,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = formik;

  useEffect(() => {
    const data: LogbookEntryDetails = logbookEntryDetails;
    setValues({
      id: data.id,
      logbook_entry_setup_master_id: data.logbook_entry_setup_master_id,
      logbook_entry_setup_detail_id: data.logbook_entry_setup_detail_id,
      column_name: data.column_name,
      details_text: data.details_text,
      details_text_desc: data.details_text_desc,
      column_data_type: data.column_data_type,
      column_value_type: data.column_value_type,
      searching_item: data.searching_item,
      required_ind: data.required_ind,
      is_show: data.is_show,
      sl_no: data.sl_no,
      search_ind: data.search_ind,
      default_ind: data.default_ind,
      status: data.status,
      creator_organization_id: data.creator_organization_id,
      updater_organization_id: data.updater_organization_id,
      created_by: data.created_by,
      updated_by: data.updated_by,
      deleted_by: data.deleted_by,
      created_at: data.created_at,
      updated_at: data.updated_at,
      deleted_at: data.deleted_at,
    });

    setLogbookEntrySetupMasterId(data.logbook_entry_setup_master_id);

    if (data?.options?.length! > 0) {
      const newOptions: any = data?.options?.map((o) => {
        return { label: o.column_name, value: o.details_text };
      });

      setInputList(newOptions);
    } else {
      setInputList([{ label: '', value: '' }]);
    }

    if (
      data.column_value_type === 'select' ||
      data.column_value_type === 'radio'
    ) {
      setColumnValueStatus(false);
    }

    if (data.required_ind === 1) {
      setRequiredStatus(true);
    } else {
      setRequiredStatus(false);
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      setShowAddForm(false);
      toast.success('Logbook entry field updated successfully');
    }
    if (isError) {
      toast.error(err?.data?.message || err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  // let logbookEntrySetupMasterOptions: SelectOption[] = [
  //   ...logbookEntrySetupMasters?.map((l: LogbookEntrySetupMaster) => ({
  //     label: l.particulars!,
  //     value: l.id!,
  //   })),
  // ];

  // let logbookEntrySetupDetailOptions: SelectOption[] = [
  //   ...logbookEntrySetupDetails?.map((l: LogbookEntryDetails) => ({
  //     label: l.column_name!,
  //     value: l.id!,
  //   })),
  // ];

  const columnValueTypeOptions: SelectOption[] = [
    { label: 'Text Field', value: 'text' },
    { label: 'Number Field', value: 'number' },
    { label: 'Textarea', value: 'textarea' },
    { label: 'Date Field', value: 'date' },
    { label: 'Dropdown', value: 'select' },
    { label: 'Checkbox', value: 'checkbox' },
    { label: 'Radio Button', value: 'radio' },
    { label: 'File Upload', value: 'file' },
  ];

  const handleRequiredStatus = (e: any) => {
    setRequiredStatus(e.target.checked);
  };

  const handleColumnValueType = (e: any) => {
    if (e.target.value === 'select' || e.target.value === 'radio') {
      setColumnValueStatus(false);
    } else {
      setColumnValueStatus(true);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            {/* <h5 className="card-header">
              <i className="fa fa-edit me-2" />
              Edit Logbook Entry Details
            </h5> */}
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                onReset={(e) => {
                  e.preventDefault();
                  resetForm();
                  setRequiredStatus(true);
                }}
              >
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3 mt-3">
                      <Label for="column_name">Field Name</Label>
                      <InputField
                        type="text"
                        placeholder="Field Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('column_name')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="column_value_type">Field Type</Label>
                      <Button
                        type="button"
                        text="Edit Option"
                        disabled={columnValueStatus}
                        className="ms-5 mb-2"
                        onClick={(e) => {
                          setModalShow(true);
                        }}
                      ></Button>
                      <InputField
                        type="select"
                        name="column_value_type"
                        id="column_value_type"
                        value={values.column_value_type}
                        placeholder="Column Value Type"
                        options={columnValueTypeOptions}
                        invalid={{ errors, touched }}
                        onChange={(e) => {
                          handleChange(e);
                          handleColumnValueType(e);
                        }}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3 mt-3">
                      <Label for="sl_no">Serial No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl. No."
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input
                          name="required_ind"
                          id="required_ind"
                          type="checkbox"
                          onChange={(e) => {
                            handleRequiredStatus(e);
                            handleChange(e);
                          }}
                          checked={requiredStatus}
                        />{' '}
                        Required
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-sm-2">
                    <Button type="submit" text="Submit" className='me-2' />
                    <Button type="reset" text="Reset" color="warning" />
                  </div>
                </div>
              </Form>

              <Modal
                onOpened={function () {
                  toast.dismiss();
                }}
                isOpen={modalShow}
                scrollable={true}
                size="xl"
                toggle={() => {
                  document.body.classList.add('no_padding');
                }}
                backdrop={'static'}
                modalTransition={{ timeout: 0 }}
                backdropTransition={{ timeout: 0 }}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Add Options
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      setModalShow(!modalShow);
                    }}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col>
                      <EditLogbookEntryDetailsOptions
                        formikProp={formik}
                        inputList={inputList}
                        setInputList={setInputList}
                        options={logbookEntryFieldOptions}
                        setModalShow={setModalShow}
                      />
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
