import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'src/rtk/saga/sagas';
import { apiSlice } from '../api/apiSlice';
import authReducer from '../features/auth/authSlice';
import profileReducer from '../features/auth/profileSlice';
import layoutSlice from '../features/layout/layoutSlice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        Layout: layoutSlice,
        auth: authReducer,
        profile: profileReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([sagaMiddleware, apiSlice.middleware]),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

export default store;
