import { apiSlice } from 'src/rtk/api/apiSlice';
import * as traineeUrl from 'src/helpers/url_helper';
import { setProfileData } from '../auth/profileSlice';

export const traineeApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['PersonalProfiles', 'EducationalProfiles', 'TrainingRecords', 'WorkExperiences', 'Documents'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPersonalProfile: builder.query<APIResponse<PersonalProfile>, void>({
        query: () => ({
          method: 'GET',
          url: `${traineeUrl.GET_PERSONAL_PROFILE}`,
        }),
        async onQueryStarted(arg, { queryFulfilled, dispatch }) {
          try {
            const result = await queryFulfilled;
            const { data } = result;

            dispatch(setProfileData(data?.data));
          } catch (err) { }
        },
        providesTags: ['PersonalProfiles', 'EducationalProfiles', 'TrainingRecords','WorkExperiences', 'Documents'],
      }),

      createPersonalProfile: builder.mutation<
        APIResponse<StorePersonalAndAddress>,
        any
      >({
        query: (data: any) => ({
          url: `${traineeUrl.CREATE_PERSONAL_PROFILE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['PersonalProfiles'],
      }),

      updatePersonalProfile: builder.mutation<
        APIResponse<StorePersonalAndAddress>,
        string
      >({
        query: (url) => ({
          url: `${traineeUrl.UPDATE_PERSONAL_PROFILE}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['PersonalProfiles'],
      }),

      deletePersonalProfile: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${traineeUrl.DELETE_PERSONAL_PROFILE}/${id}`,
        }),
        invalidatesTags: ['PersonalProfiles'],
      }),

      getStatusUpdate: builder.query<APIResponse<number>, void>({
        query: () => ({
          method: 'GET',
          url: `${traineeUrl.GET_STATUS_UPDATE}`,
        }),        
      }),

      getTraineeIrregularLogEntryMessage: builder.query<APIResponse<TraineeIrregularLogEntryMessage[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${traineeUrl.GET_TRAINEE_IRREGULAR_LOG_ENTRY_MESSAGE}`,
        }),        
      }),
    }),
  });

export const {
  useGetPersonalProfileQuery,
  useCreatePersonalProfileMutation,
  useUpdatePersonalProfileMutation,
  useDeletePersonalProfileMutation,
  useLazyGetStatusUpdateQuery,
  useGetTraineeIrregularLogEntryMessageQuery,
  useLazyGetTraineeIrregularLogEntryMessageQuery,
} = traineeApi;
