import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button as BsButton, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useGetDepartmentByIdQuery, useGetOTInfoQuery, useRegisterDepartmentStepThreeMutation } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import * as Yup from 'yup';

const initialValues: OTInfoEntry = {
    ot_info_id: '',
    form_b_department_id: '',
    separate_sterilization_area: 0,
    separate_sterilization_inspection_status: '',
    sterilization_facilities: 0,
    sterilization_facilities_details: '',
    sterilization_inspection_status: '',
    resuscitation_equipment: 0,
    resuscitation_inspection_status: '',
    anesthetics_equipment: 0,
    anesthetics_eqpmnt_inspection_status: '',
    surgical_equipment: 0,
    surgical_instrument_inspection_status: '',
    speciality_surgical_equipment: 0,
    specialty_instrument_inspection_status: '',
    recovery_room: 0,
    bed_strength: '',
    number_of_operating_session_per_week: 0,
    average_number_of_major_operation: 0,
    average_number_of_minor_operation: 0,
    average_number_of_emergency_operation: 0,
};

const InstituteDeptRegistrationStepThree = (props: any) => {
    const { goToNext, goToPrevious, instituteName, departmentId, formBDeptId, isDisabled, renewDepartment } = props;

    const [registerDepartmentStepThree, { data: departmentStepThreeData, isSuccess: isDepartmentStepThreeDataSuccess, isLoading: isDepartmentStepThreeDataLoading, isError: isDepartmentStepThreeDataError, error: departmentStepThreeDataError }] =
        useRegisterDepartmentStepThreeMutation();

    const { data: submittedDepartmentData, isSuccess: isSubmittedDepartmentDataSuccess, isError: isSubmittedDepartmentDataError, error: submittedDepartmentDataError } = useGetDepartmentByIdQuery(formBDeptId);

    const { data: OTInfoData, isSuccess: isOTInfoDataSuccess, isError: isOTInfoDataError, error: OTInfoDataError } = useGetOTInfoQuery(departmentId);

    // console.log(OTInfoData);

    const err: CustomError = departmentStepThreeDataError as CustomError;

    document.title = renewDepartment === true ? 'Renew Department | BCPS' : 'Department Registration | BCPS';

    const formik = useFormik({
        initialValues,

        validationSchema: Yup.object({
            bed_strength: Yup.string().when('recovery_room', { is: 1, then: Yup.string().required('Required field').nullable() }),
        }),

        onSubmit: (values: OTInfoEntry) => {
            console.log(values);

            values.form_b_department_id = departmentId;

            registerDepartmentStepThree(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    useEffect(() => {
        if (OTInfoData?.data?.id && !isOTInfoDataError) {
            const OTInfo: OTInfoGet = OTInfoData?.data;
            setValues({
                ot_info_id: OTInfo?.id,
                form_b_department_id: OTInfo?.form_b_department_id,
                separate_sterilization_area: OTInfo?.separate_sterilization_area ?? 0,
                separate_sterilization_inspection_status: OTInfo?.separate_sterilization_inspection_status ?? 'Non Acceptable',
                sterilization_facilities: OTInfo?.sterilization_facilities ?? 0,
                sterilization_facilities_details: OTInfo?.sterilization_facilities_details,
                sterilization_inspection_status: OTInfo?.sterilization_inspection_status ?? 'Non Acceptable',
                resuscitation_equipment: OTInfo?.resuscitation_equipment ?? 0,
                resuscitation_inspection_status: OTInfo?.resuscitation_inspection_status ?? 'Non Acceptable',
                anesthetics_equipment: OTInfo?.anesthetics_equipment ?? 0,
                anesthetics_eqpmnt_inspection_status: OTInfo?.anesthetics_eqpmnt_inspection_status ?? 'Non Acceptable',
                surgical_equipment: OTInfo?.surgical_equipment ?? 0,
                surgical_instrument_inspection_status: OTInfo?.surgical_instrument_inspection_status ?? 'Non Acceptable',
                speciality_surgical_equipment: OTInfo?.speciality_surgical_equipment ?? 0,
                specialty_instrument_inspection_status: OTInfo?.specialty_instrument_inspection_status ?? 'Non Acceptable',
                recovery_room: OTInfo?.recovery_room ?? 0,
                bed_strength: OTInfo?.bed_strength,
                number_of_operating_session_per_week: OTInfo?.number_of_operating_session_per_week,
                average_number_of_major_operation: OTInfo?.average_number_of_major_operation,
                average_number_of_minor_operation: OTInfo?.average_number_of_minor_operation,
                average_number_of_emergency_operation: OTInfo?.average_number_of_emergency_operation,
            });
        }
    }, [OTInfoData, isOTInfoDataError]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (isDepartmentStepThreeDataSuccess) {
            handleFormReset();
            toast.success('Operation theatre information registered successfully');
            goToNext();
        }
        if (isDepartmentStepThreeDataError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isDepartmentStepThreeDataSuccess, isDepartmentStepThreeDataError]);

    const handleFormReset = () => {
        resetForm();
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    title="Training Accreditation Application Form"
                    breadcrumbItem={[{ link: '/institute-registration-list', name: 'Department List' }]}
                />
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0 flex-grow-1">
                                    <span>Part-B : Information about Relevant Department (Continued)</span>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Row className='justify-content-evenly'>
                                                <Col sm={12} md={6}>
                                                    <FormGroup className="col-sm-12 mb-3">
                                                        <Label for="institute_name">Institute Name</Label>
                                                        <InputField
                                                            id="institute_name"
                                                            name="institute_name"
                                                            type="text"
                                                            value={instituteName}
                                                            placeholder=""
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup className="col-sm-12 mb-3">
                                                        <Label for="department_name">Name of the Department</Label>
                                                        <InputField
                                                            id="department_name"
                                                            name="department_name"
                                                            type="text"
                                                            value={submittedDepartmentData?.data?.department_name!}
                                                            placeholder=""
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'>
                                                        <i className="fa-solid fa-5" style={{ color: '#f8f9fc' }}></i>
                                                        <span style={{ color: '#f8f9fc' }} className='mx-1'>.</span>
                                                        <i className="fa-solid fa-1" style={{ color: '#f8f9fc' }}></i>
                                                    </span>
                                                    Information about Operation Theatre
                                                </legend>

                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label className="font-size-14 mb-3" sm={4}>(i) Separate sterilization area:</Label>
                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="separateSterilizationArea"
                                                                            id="separateSterilizationAreaYes"
                                                                            value={1}
                                                                            checked={values.separate_sterilization_area === 1}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('separate_sterilization_area', 1);
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="separateSterilizationAreaYes">Yes</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="separateSterilizationArea"
                                                                            id="separateSterilizationAreaNo"
                                                                            value={0}
                                                                            checked={values.separate_sterilization_area === 0}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('separate_sterilization_area', 0);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="separateSterilizationAreaNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2} className='ms-5'>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="separateSterilizationAreaStatus"
                                                                            id="separateSterilizationAreaStatusAcceptable"
                                                                            value={'Acceptable'}
                                                                            checked={values.separate_sterilization_inspection_status === 'Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('separate_sterilization_inspection_status', 'Acceptable');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="separateSterilizationAreaStatusAcceptable">Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="separateSterilizationAreaStatus"
                                                                            id="separateSterilizationAreaStatusNonAcceptable"
                                                                            value={'Non Acceptable'}
                                                                            checked={values.separate_sterilization_inspection_status === 'Non Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('separate_sterilization_inspection_status', 'Non Acceptable');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="separateSterilizationAreaStatusNonAcceptable">Non Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label className="font-size-14 mb-3" sm={4}>(ii) Sterilization facilities:</Label>
                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="sterilizationFacilities"
                                                                            id="sterilizationFacilitiesYes"
                                                                            value={1}
                                                                            checked={values.sterilization_facilities === 1}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('sterilization_facilities', 1);
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="sterilizationFacilitiesYes">Yes</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="sterilizationFacilities"
                                                                            id="sterilizationFacilitiesNo"
                                                                            value={0}
                                                                            checked={values.sterilization_facilities === 0}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('sterilization_facilities', 0);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="sterilizationFacilitiesNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2} className='ms-5'>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="sterilizationFacilitiesStatus"
                                                                            id="sterilizationFacilitiesStatusAcceptable"
                                                                            value={'Acceptable'}
                                                                            checked={values.sterilization_inspection_status === 'Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('sterilization_inspection_status', 'Acceptable');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="sterilizationFacilitiesStatusAcceptable">Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="sterilizationFacilitiesStatus"
                                                                            id="sterilizationFacilitiesStatusNonAcceptable"
                                                                            value={'Non Acceptable'}
                                                                            checked={values.sterilization_inspection_status === 'Non Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('sterilization_inspection_status', 'Non Acceptable');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="sterilizationFacilitiesStatusNonAcceptable">Non Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label className="font-size-14 mb-3" sm={4}>(iii) Restitution facilities:</Label>
                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="restitutionFacilities"
                                                                            id="restitutionFacilitiesYes"
                                                                            value={1}
                                                                            checked={values.resuscitation_equipment === 1}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('resuscitation_equipment', 1);
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="restitutionFacilitiesYes">Yes</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="restitutionFacilities"
                                                                            id="restitutionFacilitiesNo"
                                                                            value={0}
                                                                            checked={values.resuscitation_equipment === 0}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('resuscitation_equipment', 0);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="restitutionFacilitiesNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2} className='ms-5'>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="restitutionFacilitiesStatus"
                                                                            id="restitutionFacilitiesStatusAcceptable"
                                                                            value={'Acceptable'}
                                                                            checked={values.resuscitation_inspection_status === 'Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('resuscitation_inspection_status', 'Acceptable');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="restitutionFacilitiesStatusAcceptable">Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="restitutionFacilitiesStatus"
                                                                            id="restitutionFacilitiesStatusNonAcceptable"
                                                                            value={'Non Acceptable'}
                                                                            checked={values.resuscitation_inspection_status === 'Non Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('resuscitation_inspection_status', 'Non Acceptable');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="restitutionFacilitiesStatusNonAcceptable">Non Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label className="font-size-14 mb-3" sm={4}>(iv) Anesthetic equipment:</Label>
                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="anestheticEquipment"
                                                                            id="anestheticEquipmentYes"
                                                                            value={1}
                                                                            checked={values.anesthetics_equipment === 1}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('anesthetics_equipment', 1);
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="anestheticEquipmentYes">Yes</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="anestheticEquipment"
                                                                            id="anestheticEquipmentNo"
                                                                            value={0}
                                                                            checked={values.anesthetics_equipment === 0}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('anesthetics_equipment', 0);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="anestheticEquipmentNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2} className='ms-5'>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="anestheticEquipmentStatus"
                                                                            id="anestheticEquipmentStatusAcceptable"
                                                                            value={'Acceptable'}
                                                                            checked={values.anesthetics_eqpmnt_inspection_status === 'Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('anesthetics_eqpmnt_inspection_status', 'Acceptable');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="anestheticEquipmentStatusAcceptable">Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="anestheticEquipmentStatus"
                                                                            id="anestheticEquipmentStatusNonAcceptable"
                                                                            value={'Non Acceptable'}
                                                                            checked={values.anesthetics_eqpmnt_inspection_status === 'Non Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('anesthetics_eqpmnt_inspection_status', 'Non Acceptable');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="anestheticEquipmentStatusNonAcceptable">Non Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label className="font-size-14 mb-3" sm={4}>(v) Surgical instruments in general:</Label>
                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="surgicalInstrumentInGeneral"
                                                                            id="surgicalInstrumentInGeneralYes"
                                                                            value={1}
                                                                            checked={values.surgical_equipment === 1}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('surgical_equipment', 1);
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="surgicalInstrumentInGeneralYes">Yes</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="surgicalInstrumentInGeneral"
                                                                            id="surgicalInstrumentInGeneralNo"
                                                                            value={0}
                                                                            checked={values.surgical_equipment === 0}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('surgical_equipment', 0);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="surgicalInstrumentInGeneralNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2} className='ms-5'>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="surgicalInstrumentInGeneralStatus"
                                                                            id="surgicalInstrumentInGeneralStatusAcceptable"
                                                                            value={'Acceptable'}
                                                                            checked={values.surgical_instrument_inspection_status === 'Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('surgical_instrument_inspection_status', 'Acceptable');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="surgicalInstrumentInGeneralStatusAcceptable">Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="surgicalInstrumentInGeneralStatus"
                                                                            id="surgicalInstrumentInGeneralStatusNonAcceptable"
                                                                            value={'Non Acceptable'}
                                                                            checked={values.surgical_instrument_inspection_status === 'Non Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('surgical_instrument_inspection_status', 'Non Acceptable');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="surgicalInstrumentInGeneralStatusNonAcceptable">Non Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            {/* {values.surgical_equipment === 1 &&
                                                                <Col sm={6}>
                                                                    <FormGroup row className="col-sm-12 mb-3">
                                                                        <Label htmlFor="file_uploading_module" sm={3}>Provide Details</Label>
                                                                        <Col sm={6}>
                                                                            <InputField
                                                                                name="file_uploading_module"
                                                                                id="file_uploading_module"
                                                                                type="file"
                                                                                placeholder=""
                                                                                invalid={{ errors, touched }}                                                                                
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                            } */}
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label className="font-size-14 mb-3" sm={4}>(vi) Specialty surgical instrument:</Label>
                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="specialtySurgicalInstrument"
                                                                            id="specialtySurgicalInstrumentYes"
                                                                            value={1}
                                                                            checked={values.speciality_surgical_equipment === 1}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('speciality_surgical_equipment', 1);
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="specialtySurgicalInstrumentYes">Yes</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="specialtySurgicalInstrument"
                                                                            id="specialtySurgicalInstrumentNo"
                                                                            value={0}
                                                                            checked={values.speciality_surgical_equipment === 0}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('speciality_surgical_equipment', 0);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="specialtySurgicalInstrumentNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2} className='ms-5'>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="specialtySurgicalInstrumentStatus"
                                                                            id="specialtySurgicalInstrumentStatusAcceptable"
                                                                            value={'Acceptable'}
                                                                            checked={values.specialty_instrument_inspection_status === 'Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('specialty_instrument_inspection_status', 'Acceptable');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="specialtySurgicalInstrumentStatusAcceptable">Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={2}>
                                                                <div className="d-inline-block mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="specialtySurgicalInstrumentStatus"
                                                                            id="specialtySurgicalInstrumentStatusNonAcceptable"
                                                                            value={'Non Acceptable'}
                                                                            checked={values.specialty_instrument_inspection_status === 'Non Acceptable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('specialty_instrument_inspection_status', 'Non Acceptable');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="specialtySurgicalInstrumentStatusNonAcceptable">Non Acceptable</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12} className='mb-4'>
                                                        <FormGroup row className="mb-3">
                                                            <Label className="font-size-14 mb-3" sm={4}>(vii) Recovery room:</Label>
                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="recoveryRoom"
                                                                            id="recoveryRoomYes"
                                                                            value={1}
                                                                            checked={values.recovery_room === 1}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('recovery_room', 1);
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="recoveryRoomYes">Yes</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="recoveryRoom"
                                                                            id="recoveryRoomNo"
                                                                            value={0}
                                                                            checked={values.recovery_room === 0}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('recovery_room', 0);
                                                                                    setFieldValue('bed_strength', '');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="recoveryRoomNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            {values.recovery_room === 1 &&
                                                                <Col sm={6}>
                                                                    <FormGroup row className="col-sm-12 mb-3">
                                                                        <Label for="bed_strength" sm={6}>What is the bed strength</Label>
                                                                        <Col sm={4}>
                                                                            <InputField
                                                                                type="text"
                                                                                placeholder=""
                                                                                invalid={{ errors, touched }}
                                                                                {...getFieldProps('bed_strength')}
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                            }
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label for="number_of_operating_session_per_week" sm={5}>(viii) Number of operating sessions per week:</Label>
                                                            <Col sm={3}>
                                                                <InputField
                                                                    min={'0'}
                                                                    type="number"
                                                                    placeholder=""
                                                                    {...getFieldProps('number_of_operating_session_per_week')}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label for="average_number_of_major_operation" sm={5}>(ix) Avg. number of major operations performed per session:</Label>
                                                            <Col sm={3}>
                                                                <InputField
                                                                    min={'0'}
                                                                    type="number"
                                                                    placeholder=""
                                                                    {...getFieldProps('average_number_of_major_operation')}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label for="average_number_of_minor_operation" sm={5}>(x) Avg. number of minor operations performed per session:</Label>
                                                            <Col sm={3}>
                                                                <InputField
                                                                    min={'0'}
                                                                    type="number"
                                                                    placeholder=""
                                                                    {...getFieldProps('average_number_of_minor_operation')}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label for="average_number_of_emergency_operation" sm={5}>(xi) Avg. number of emergency operation done per week:</Label>
                                                            <Col sm={3}>
                                                                <InputField
                                                                    min={'0'}
                                                                    type="number"
                                                                    placeholder=""
                                                                    {...getFieldProps('average_number_of_emergency_operation')}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                        </Col>
                                    </Row>

                                    <FormGroup row className='justify-content-between mt-5'>
                                        <Col sm={9}>
                                            <BsButton color="warning" style={isDisabled === true ? { pointerEvents: 'auto' } : {}} className='me-3 text-start' onClick={() => {
                                                goToPrevious();
                                            }}>
                                                {'Previous'}
                                            </BsButton>
                                        </Col>

                                        <Col sm={3}>
                                            {isDisabled !== true ?
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="secondary" className='me-3' onClick={() => {
                                                        handleFormReset();
                                                    }}>
                                                        {'Reset'}
                                                    </BsButton>
                                                    <BsButton color="success" disabled={isDepartmentStepThreeDataLoading} onClick={() => {
                                                        handleSubmit();
                                                    }}>
                                                        {'Save & Next'}
                                                    </BsButton>
                                                </div>
                                                :
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="success" style={{ pointerEvents: 'auto' }} onClick={() => {
                                                        goToNext();
                                                    }}>
                                                        {'Next'}
                                                    </BsButton>
                                                </div>}
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </div >
    );
};

export default InstituteDeptRegistrationStepThree;