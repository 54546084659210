import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Col, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateLogbookEntrySetupDetailMutation } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import * as Yup from 'yup';
import AddLogbookEntryDetailsOptions from './AddLogbookEntryDetailsOptions';
import UpdatedLogbookEntryDetailsList from './UpdatedLogbookEntryDetailsList';
import loader from '../../../assets/images/loader.gif';

const initialValues: LogbookEntryDetails = {
  id: '',
  logbook_entry_setup_master_id: '',
  logbook_entry_setup_detail_id: '',
  column_name: '',
  details_text: '',
  details_text_desc: '',
  column_data_type: '',
  column_value_type: '',
  searching_item: '',
  required_ind: 1,
  is_show: 1,
  sl_no: 0,
  search_ind: 1,
  default_ind: 1,
  status: true,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

const AddLogbookEntryDetailsFields = (props: any) => {
  const { logbookEntrySetupMasterId, logbookGroup, logbookEntryFieldList, setSearch, submitStatus, readOnly } =
    props;
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [inputList, setInputList] = useState<any>([{ label: '', value: '' }]);

  const [createLogbookEntryDetails, { data, isSuccess, isError, isLoading, error }] =
    useCreateLogbookEntrySetupDetailMutation();

  const [requiredStatus, setRequiredStatus] = useState<boolean>(true);

  const [columnValueStatus, setColumnValueStatus] = useState<boolean>(true);

  const err: CustomError = error as CustomError;

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      column_name: Yup.string().trim().required('Field name is required'),
      // logbook_entry_setup_master_id: Yup.string().required('Please select the logbook entry setup master'),
    }),

    onSubmit: (values) => {
      if (requiredStatus) {
        values.required_ind = 1;
      } else {
        values.required_ind = 0;
      }

      values.logbook_entry_setup_master_id = logbookEntrySetupMasterId;

      createLogbookEntryDetails({
        ...values,
      });

      console.log(values);
    },
  });

  const {
    handleSubmit,
    handleChange,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = formik;

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook entry details created successfully');
    }
    if (isError) {
      toast.error(err?.data?.message || err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const columnValueTypeOptions: SelectOption[] = [
    { label: 'Text Field', value: 'text' },
    { label: 'Number Field', value: 'number' },
    { label: 'Textarea', value: 'textarea' },
    { label: 'Date Field', value: 'date' },
    { label: 'Dropdown', value: 'select' },
    { label: 'Checkbox', value: 'checkbox' },
    { label: 'Radio Button', value: 'radio' },
    { label: 'File Upload', value: 'file' },
  ];

  const handleColumnValueType = (e: any) => {
    if (e.target.value === 'select' || e.target.value === 'radio') {
      setColumnValueStatus(false);
    } else {
      setColumnValueStatus(true);
    }
  };

  const handleRequiredStatus = (e: any) => {
    setRequiredStatus(e.target.checked);
  };

  const modalShowHandler = (modalState: boolean) => {
    setModalShow(modalState);
  };

  return (
    <>
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Form
        className="custom-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        onReset={(e) => {
          resetForm();
          setRequiredStatus(true);
        }}
      >
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3 mt-3">
              <Label for="column_name">Field Name</Label>
              <InputField
                type="text"
                placeholder="Column Name"
                invalid={{ errors, touched }}
                {...getFieldProps('column_name')}
                readOnly={readOnly}
              />
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3 mt-3">
              <Label for="column_value_type">Field Type</Label>

              <div className='input-field-group'>
                <InputField
                  type="select"
                  name="column_value_type"
                  id="column_value_type"
                  value={values.column_value_type}
                  placeholder="Column Value Type"
                  options={columnValueTypeOptions}
                  invalid={{ errors, touched }}
                  onChange={(e) => {
                    handleChange(e);
                    handleColumnValueType(e);
                  }}
                  disabled={readOnly}
                />
                <Button
                  type="button"
                  text="Add Option"
                  disabled={readOnly || columnValueStatus}
                  onClick={(e) => {
                    setModalShow(true);
                  }}
                ></Button>
              </div>
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3 mt-3">
              <Label for="sl_no">Serial No</Label>
              <InputField
                type="number"
                placeholder="Sl No."
                invalid={{ errors, touched }}
                {...getFieldProps('sl_no')}
                readOnly={readOnly}
              />
            </FormGroup>
          </div>

          <div className="col-sm-12">
            <FormGroup check className="mb-3 mt-4">
              <Label check>
                <Input
                  name="required_ind"
                  id="required_ind"
                  type="checkbox"
                  onChange={(e) => {
                    handleRequiredStatus(e);
                    handleChange(e);
                  }}
                  checked={requiredStatus}
                  disabled={readOnly}
                />{' '}
                Required?
              </Label>
            </FormGroup>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="row justify-content-center">
            <div className="col-sm-2">
              <Button
                type="submit"
                color="secondary"
                text="Submit"
                className="me-2 mb-2"
              ></Button>
              <Button
                type="reset"
                color="warning"
                text="Reset"
                className="me-2 mb-2"
              ></Button>
            </div>
          </div>
        </div>
      </Form>

      {logbookEntrySetupMasterId &&
        logbookGroup &&
        submitStatus &&
        logbookEntrySetupMasterId !== '' && (
          <UpdatedLogbookEntryDetailsList
            id={logbookEntrySetupMasterId}
            logbookGroup={logbookGroup}
            logbookEntryFieldList={logbookEntryFieldList}
            setSearch={setSearch}
          />
        )}

      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={modalShow}
        scrollable={true}
        size="xl"
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Add Options
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalShow(!modalShow);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <AddLogbookEntryDetailsOptions
                formikProp={formik}
                inputList={inputList}
                setInputList={setInputList}
                setModalShow={setModalShow}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AddLogbookEntryDetailsFields;
