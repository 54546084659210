import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from 'src/config';
import { RootState } from 'src/rtk/app/store';

// create error type with FetchBaseQueryError

const baseQuery = fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: async (headers, { getState, endpoint }) => {
        const state = getState() as RootState;
        const token = state.auth.token;

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
            headers.set("accept", "application/json");
        }
        return headers;
    },
});

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions);

        // error handling
        if (result?.error) {
            if (result.error?.status === 401) {
                localStorage.removeItem(
                    'bcps-auth-token-and-user'
                );
                window.location.reload();
            }

            const data = (result.error.data as any) || {};
            const error: any = {
                status: result.error?.status,
                message: data?.message || '',
                data: data?.data,
            };
            result = { ...result, error };
        }
        return result;
    },
    keepUnusedDataFor: 3600,
    endpoints: (builder) => ({}),
});
