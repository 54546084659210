import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { InputField } from "src/components";

const SubSpecialtyOfTheRelevantDepartment = (props: any) => {
    const { inputList, setInputList, isDisabled } = props;

    const [plusButtonDisabled, setPlusButtonDisabled] = useState<boolean>(true);
    const [nameOfSubSpecialty, setNameOfSubSpecialty] = useState<string>('');
    const [totalNumberOfBeds, setTotalNumberOfBeds] = useState<string>('');
    const [numberOfPayingBeds, setNumberOfPayingBeds] = useState<string>('');
    const [numberOfNonPayingBeds, setNumberOfNonPayingBeds] = useState<string>('');
    const [numberOfCabins, setNumberOfCabins] = useState<string>('');

    const handleAddFields = () => {
        const values = [...inputList];

        values.push({
            nameOfSubSpecialty: nameOfSubSpecialty,
            totalNumberOfBeds: +totalNumberOfBeds,
            numberOfPayingBeds: +numberOfPayingBeds,
            numberOfNonPayingBeds: +numberOfNonPayingBeds,
            numberOfCabins: +numberOfCabins,
        });

        setInputList(values);
        setNameOfSubSpecialty('');
        setTotalNumberOfBeds('');
        setNumberOfPayingBeds('');
        setNumberOfNonPayingBeds('');
        setNumberOfCabins('');
        setPlusButtonDisabled(true);
    };

    const handleRemoveFields = (index: number) => {
        const values = [...inputList];
        values.splice(index, 1);
        setInputList(values);
    };

    const handleNameOfSubSpecialty = (event: any) => {
        const { name, value } = event.target;
        setNameOfSubSpecialty(value);
    };    

    const handleNumberOfPayingBeds = (event: any) => {
        const { name, value } = event.target;
        setNumberOfPayingBeds(value);
    };

    const handleNumberOfNonPayingBeds = (event: any) => {
        const { name, value } = event.target;
        setNumberOfNonPayingBeds(value);
    };

    const handleNumberOfCabins = (event: any) => {
        const { name, value } = event.target;
        setNumberOfCabins(value);
    };

    useEffect(() => {
        if (nameOfSubSpecialty && totalNumberOfBeds && numberOfPayingBeds && numberOfNonPayingBeds && numberOfCabins) {
            setPlusButtonDisabled(false);
        }
    }, [nameOfSubSpecialty, totalNumberOfBeds, numberOfPayingBeds, numberOfNonPayingBeds, numberOfCabins]);

    useEffect(() => {
        const totalNumber = +numberOfPayingBeds! + +numberOfNonPayingBeds! + +numberOfCabins!;
        setTotalNumberOfBeds(totalNumber.toString());
    }, [numberOfPayingBeds, numberOfNonPayingBeds, numberOfCabins]);

    console.log(inputList);

    const columns: TableColumn<any>[] = [
        {
            name: <div className="text-center">Sl. No.</div>,
            width: '100px',
            cell: (row, index) => index + 1,
            center: true,
        },

        {
            name: <div className="text-center">Name of the sub-specialties</div>,
            selector: (row) => row.nameOfSubSpecialty,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Total number of beds</div>,
            selector: (row) => row.totalNumberOfBeds,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Number of paying beds</div>,
            selector: (row) => row.numberOfPayingBeds,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Number of non-paying beds</div>,
            selector: (row) => row.numberOfNonPayingBeds,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Number of cabins</div>,
            selector: (row) => row.numberOfCabins,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '150px',
            cell: (row) => (
                <>
                    <button
                        type="button"
                        style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                        className={'btn btn-danger mb-2'}
                        onClick={() => handleRemoveFields(inputList?.indexOf(row))}
                    >
                        <i className="fa fa-minus-circle" />
                    </button>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <>
            <Row>
                <Col sm={12}>
                    <fieldset className='border p-2 mt-3'>                        
                        <FormGroup className="col-sm-12 mb-3">
                            <Label for="nameOfSubSpecialty" className="required-field"><span className='px-1 bg-primary rounded'><i className="fa-solid fa-b" style={{ color: '#f8f9fc' }}></i></span> Name of the sub-specialties</Label>
                            <InputField
                                type="text"
                                className="form-control"
                                name="nameOfSubSpecialty"
                                id="nameOfSubSpecialty"
                                value={nameOfSubSpecialty}
                                onChange={(event) => handleNameOfSubSpecialty(event)}
                            />
                        </FormGroup>

                        <h5 className='font-size-14 mb-3 required-field'><span className='px-1 bg-primary rounded'><i className="fa-solid fa-c" style={{ color: '#f8f9fc' }}></i></span> Number of beds in the sub-specialty departments:</h5>

                        <FormGroup row className="col-sm-12 mb-3">
                            <Label for="totalNumberOfBeds" className="required-field" sm={8}>Total number of beds</Label>
                            <Col sm={3}>
                                <InputField
                                    type="number"
                                    className="form-control"
                                    name="totalNumberOfBeds"
                                    id="totalNumberOfBeds"
                                    value={totalNumberOfBeds}
                                    disabled                                    
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row className="col-sm-12 mb-3">
                            <Label for="numberOfPayingBeds" className="required-field" sm={8}>Number of paying beds</Label>
                            <Col sm={3}>
                                <InputField
                                    min={'0'}
                                    type="number"
                                    className="form-control"
                                    name="numberOfPayingBeds"
                                    id="numberOfPayingBeds"
                                    value={numberOfPayingBeds}
                                    onChange={(event) => handleNumberOfPayingBeds(event)}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row className="col-sm-12 mb-3">
                            <Label for="numberOfNonPayingBeds" className="required-field" sm={8}>Number of non-paying beds</Label>
                            <Col sm={3}>
                                <InputField
                                    min={'0'}
                                    type="number"
                                    id="numberOfNonPayingBeds"
                                    name="numberOfNonPayingBeds"
                                    value={numberOfNonPayingBeds}
                                    onChange={(event) => handleNumberOfNonPayingBeds(event)}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row className="col-sm-12 mb-3">
                            <Label for="totalBed" className="required-field" sm={8}>Number of cabins</Label>
                            <Col sm={3}>
                                <InputField
                                    min={'0'}
                                    type="number"
                                    name="numberOfCabins"
                                    id="numberOfCabins"
                                    value={numberOfCabins}
                                    onChange={(event) => handleNumberOfCabins(event)}
                                />
                            </Col>
                            <Col sm={1}>
                                {!plusButtonDisabled ?
                                    (<button
                                        type="button"
                                        className={'btn btn-sm btn-secondary mt-2'}
                                        onClick={handleAddFields}
                                    >
                                        <i className="fa fa-plus-circle" />
                                    </button>)
                                    :
                                    (<button
                                        type="button"
                                        className={'btn btn-sm btn-secondary mt-2'}
                                        onClick={handleAddFields}
                                        disabled
                                    >
                                        <i className="fa fa-plus-circle" />
                                    </button>)}
                            </Col>
                        </FormGroup>                        
                    </fieldset>
                </Col>
            </Row>

            {inputList?.length > 0 &&
                <div className="row mb-5 mx-1 mt-4" style={isDisabled === true ? { pointerEvents: 'auto' } : {}}>
                    <div className="col-sm-12">
                        <DataTable
                            columns={columns}
                            data={inputList?.length > 0 ? inputList : []}
                            expandOnRowClicked
                            highlightOnHover
                        />
                    </div>
                </div>}
        </>
    );
};

export default SubSpecialtyOfTheRelevantDepartment;

