import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { config } from 'src/config';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetLookupChildQuery } from 'src/rtk/features/setup/lookup/lookupChildApi';
import { useGetNoticeTemplateListsQuery } from 'src/rtk/features/setup/noticeTemplateList/setupNoticeTemplateListApi';
import { useDeleteTrnNoticeMasterListMutation } from 'src/rtk/features/setup/trnNoticeMaster/setupTrnNoticeMaster';

interface Props {
  notice: TransactionNoticeMaster;
  index: number;
}

const NoticeMasterTableRow = ({ notice, index }: Props) => {
  const history = useHistory();

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [deleteNotice, { isSuccess, isLoading, isError }] =
    useDeleteTrnNoticeMasterListMutation();

  const { data } = useGetDepartmentsQuery();
  const departments = data?.data || [];

  const { data: noticeTempalteData } = useGetNoticeTemplateListsQuery();
  const noticeTemplates = noticeTempalteData?.data || [];

  const { data: noticeTypeLookup, isError: isNoticeTypeLookupFetchError } =
    useGetLookupChildQuery(notice.notice_type!);

  const deleteHandler = (modalState: boolean, noticeId: string) => {
    setModalShow(modalState);
    setInfoId(noticeId);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Notice deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting notice');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={notice.id}>
      <th scope="row">{index + 1}</th>
      <td>
        {
          noticeTemplates?.find((d) => d.id === notice.notice_template_list_id)
            ?.notice_subject
        }
      </td>
      <td>{notice.notice_no}</td>
      <td>{notice.notice_subject}</td>
      <td>{notice.notice_type}</td>
      <td>
        {
          notice.department_id
        }
      </td>
      {/* <td>
        <img src={config?.APP_URL + notice.notice_header!} alt="" />
      </td> */}
      <td>
        <span dangerouslySetInnerHTML={{ __html: JSON.parse(notice?.notice_body!)?.editorHtml }}></span>
      </td>
      {/* <td>{notice.notice_footer}</td> */}
      <td>{notice.notice_date?.toString()}</td>
      <td>{notice.status ? 'Active' : 'Inactive'}</td>
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(`/setup/trn-notice-master/update/${notice.id}`)
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, notice.id!)}
          />

          <Button
            size="sm"
            text="Attachments"
            color="info"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() =>
              history.push(`/setup/trn-notice-attachment/${notice.id}`)
            }
          />
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteNotice}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default NoticeMasterTableRow;
