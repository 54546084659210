import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
  deleteInfomation?: any;
}

const DeleteInformationModal = (props: Props) => {
  const { isOpen, toggle, id, deleteInfomation } = props;

  const handleDelete = (e: any) => {
    deleteInfomation(id);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" backdrop={'static'} className="custom-modal">
      <div className="custom-modal-header">
        <i className="fa-regular fa-circle-xmark fa-2xl"></i>
      </div>
      
      <ModalBody className="custom-modal-body">
        <div className="text-center">
          <h4 className="modal-question mb-4">Are you sure you want to delete this item?</h4>
          <p className="text-muted">This action cannot be undone. Please confirm you want to proceed.</p>
        </div>
      </ModalBody>

      <ModalFooter className="custom-modal-footer">
        <Button color="danger" className="btn-lg custom-delete-btn" onClick={handleDelete}>
          <i className="fa-solid fa-check me-2" /> Yes, Delete
        </Button>
        <Button color="secondary" className="btn-lg custom-cancel-btn" onClick={toggle}>
          <i className="fa-solid fa-times me-2" /> No, Cancel
        </Button>
      </ModalFooter>
    </Modal>

  );
};

export default DeleteInformationModal;
